import request from '@/utils/request'

export default {
  adminList(params) {
    return request({
      url: '/v1/adminList',
      method: 'get',
      params
    })
  },
  getOne(id) {
    return request({
      url: `/v1/admin/${id}`,
      method: 'get'
    })
  },
  update(data) {
    return request({
      url: '/v1/adminUpdate',
      method: 'post',
      data
    })
  },
  create(data) {
    return request({
      url: '/v1/adminAdd',
      method: 'post',
      data
    })
  },
  delete(id) {
    return request({
      url: `/v1/adminDel/${id}`,
      method: 'get'
    })
  },
  tree() {
    return request({
      url: `/v1/departmentTree`,
      method: 'get'
    })
  },
  changePassword(data) {
    return request({
      url: '/v1/adminChangePassword',
      method: 'post',
      data
    })
  }
}

import request from '@/utils/request'

export default {
  partsList(params) {
    return request({
      url: '/v1/partsList',
      method: 'get',
      params
    })
  },
  getOne(id) {
    return request({
      url: `/v1/parts/${id}`,
      method: 'get'
    })
  },
  update(data) {
    return request({
      url: '/v1/partsUpdate',
      method: 'post',
      data
    })
  },
  create(data) {
    return request({
      url: '/v1/partsAdd',
      method: 'post',
      data
    })
  },
  delete(id) {
    return request({
      url: `/v1/partsDel/${id}`,
      method: 'get'
    })
  },
  providerAppend(data) {
    return request({
      url: '/v1/partsProviderAppend',
      method: 'post',
      data
    })
  },
  providerRemove(id) {
    return request({
      url: `/v1/partsProviderRemove/${id}`,
      method: 'get'
    })
  },
  eqAppend(data) {
    return request({
      url: '/v1/partsUsedEquipmentAppend',
      method: 'post',
      data
    })
  },
  eqRemove(id) {
    return request({
      url: `/v1/partsUsedEquipmentRemove/${id}`,
      method: 'get'
    })
  },
  eqCategoryAppend(data) {
    return request({
      url: '/v1/partsUsedEquipmentCategoryAppend',
      method: 'post',
      data
    })
  },
  eqCategoryRemove(id) {
    return request({
      url: `/v1/partsUsedEquipmentCategoryRemove/${id}`,
      method: 'get'
    })
  }
}
